import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { Link } from "@nextui-org/react";
import { NEWS_TYPE } from '@/components/helpers/constant.js';
import SharedService from "@/services/shared-service";
import { EffectCards, Autoplay } from 'swiper/modules';

function DossilContent({ data, categoryCode }) {
    var currentCategory = Object.values(NEWS_TYPE).find(item => item.code === categoryCode || item.link === categoryCode);

    return (
        <>
            <div className="mx-10 text-2xl font-bold text-center pt-4">
                <Link href={`/chuyen-de/${currentCategory?.code}`} className="md:mx-10 text-xl md:text-2xl font-bold text-white">
                    { currentCategory?.name.toLocaleUpperCase() }
                </Link>
            </div>

            <div className="text-center font-semibold">
                <p>{data.authorName}</p>
            </div>

            <Link href={SharedService.getPostPath(data)} className="block px-5">
                <div className="text-white text-justify sm:text-center mt-5 mx-5">
                    <span
                        className="inline-block description"
                        dangerouslySetInnerHTML={{ __html: data.metaDescription }}
                    ></span>
                </div>
                <div className="text-secondary text-center my-5">
                    <span className="inline-block text-xl title">{data.title}</span>
                </div>
            </Link>
        </>
    );
}

export default function SwiperCustom({ data, categoryCode }) {
    const dossils = data;
    
    if (!dossils?.total || dossils.value.length === 0) {
        return null;
    }

    return (
        <>
            <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards, Autoplay]}
                initialSlide={4}
                className="mySwiper w-[92%] h-[400px] md:h-[350px] lg:h-[280px] swiper-custom"
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                cardsEffect={{
                    rotate: 0,
                    slideShadows: false, 
                    depth: 100,
                    perspective: 1000
                }}
            >
                {dossils.value.map((dossil, index) => (
                    <SwiperSlide key={index} className="flex flex-col rounded-[5px] bg-gray-800/50 !bg-cover !bg-center !bg-[url('/webp/book.webp')] !bg-blend-darken">
                        <DossilContent data={dossil} categoryCode={ categoryCode } />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
